<template>
  <v-form @keydown.enter="refreshTable" class="mb-3">
    <v-row>
      <v-col cols="12" md="2">
        <v-text-field
            v-model="filterObj.name"
            outlined
            dense
            clearable
            :label="$t('schools.schoolName')"
            @keydown.enter="save()"
            hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
            v-model="filterObj.domain_scid"
            outlined
            dense
            clearable
            :label="$t('schools.domain_scid')"
            @keydown.enter="save()"
            hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
            outlined
            hide-details
            dense
            persistent-hint
            clearable
            v-model="filterObj.governorate_id"
            :items="governorates"
            item-text="name"
            item-value="id"
            :label="$t('directorates.governorate')"
            @keydown.enter="save()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
            outlined
            hide-details
            dense
            persistent-hint
            clearable
            v-model="filterObj.directorate_id"
            :items="directorates_filtered"
            item-text="name"
            item-value="id"
            :label="$t('directorates.directorate')"
            @keydown.enter="save()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
            outlined
            hide-details
            dense
            persistent-hint
            v-model="filterObj.active"
            :items="activeStatus"
            item-text="text"
            item-value="value"
            :label="$t('schools.status')"
            @keydown.enter="save()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
            :label="$t('schools.schoolYear')"
            :items="schoolYears"
            outlined
            dense
            v-model="localSelectedSchoolYear"
            item-text="school_year"
            item-value="id"
            hide-details
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
            outlined
            hide-details
            dense
            v-model="filterObj.free"
            :items="freeList"
            item-text="text"
            item-value="value"
            :label="$t('schools.isFree')"
            @keydown.enter="save()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
            outlined
            hide-details
            dense
            v-model="filterObj.owner"
            :items="owner"
            item-text="text"
            item-value="value"
            :label="$t('schools.ownership')"
            @keydown.enter="save()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-select
            outlined
            hide-details
            dense
            clearable
            v-model="filterObj.financial_status_student"
            :items="financial_status_student"
            item-text="text"
            item-value="value"
            :label="$t('schools.financial_status_student')"
            @keydown.enter="save()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2" v-if="$store.getters.isInRole(120)">
        <v-select
            outlined
            dense
            clearable
            hide-details
            v-model="filterObj.agency_id"
            :items="agencies"
            item-text="title"
            item-value="id"
            label="الوكالة"
            @keydown.enter="save()"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2">
        <v-menu
            ref="menu"
            v-model="createdAtMenu"
            :close-on-content-click="false"
            :return-value.sync="filterObj.createdAt"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                outlined
                dense
                clearable
                hide-details
                @click:clear="filterObj.createdAt = null; refreshTable()"
                @keydown.enter="save()"
                :label="$t('schools.created_at')"
                v-model="filterObj.createdAt"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="filterObj.createdAt"
              no-title
              range
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="createdAtMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.menu.save(filterObj.createdAt); refreshTable()"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto" class="pt-2">
        <v-subheader>تصفية حسب الاعداد :</v-subheader>
      </v-col>
      <v-col cols="auto">
        <v-menu
            v-model="filtersMenuStudent"
            :close-on-content-click="false"
            :nudge-width="250"
            offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                :color="financeFiltersStudentActive ? 'warning' : 'light'"
                v-bind="attrs"
                v-on="on"
            >
              تصفية حسب عدد الطلاب
            </v-btn>
          </template>

          <v-card >
            <v-card-title class="subtitle-1 primary" style="color: white;">  تصفية حسب عدد الطلاب</v-card-title>

            <v-list>
              <v-list-item>
                <v-row>
                  <v-col cols="4">
                    <span>عدد المتوقعين</span>
                  </v-col>
                  <v-col cols="8">
                    <v-range-slider
                        track-color="light"
                        color="info"
                        max="2000"
                        min="0"
                        step="10"
                        v-model="financeFiltersStudent.expected"
                    >
                      <template v-slot:thumb-label="props" >
                        {{ props.value }}
                      </template>
                    </v-range-slider></v-col>
                </v-row>
              </v-list-item>
              <v-list-item>
                <v-row>
                  <v-col cols="4">
                    <span>الفعالين هذا الاسبوع</span>
                  </v-col>
                  <v-col cols="8">
                    <v-range-slider
                        track-color="light"
                        color="purple"
                        max="2000"
                        min="0"
                        step="10"
                        v-model="financeFiltersStudent.week_active"
                    >
                      <template v-slot:thumb-label="props" >
                        {{ props.value }}
                      </template>
                    </v-range-slider></v-col>
                </v-row>
              </v-list-item>
              <v-list-item>
                <v-row>
                  <v-col cols="4">
                    <span>عدد الحالي</span>
                  </v-col>
                  <v-col cols="8">
                    <v-range-slider
                        track-color="light"
                        color="primary"
                        max="2000"
                        min="0"
                        step="10"
                        v-model="financeFiltersStudent.current"
                    >
                      <template v-slot:thumb-label="props" >
                        {{ props.value }}
                      </template>
                    </v-range-slider></v-col>
                </v-row>
              </v-list-item>
              <v-list-item v-if="$store.getters.isInRole(102)">
                <v-row>
                  <v-col cols="4">
                    <span>عدد المشتركين</span>
                  </v-col>
                  <v-col cols="8">
                    <v-range-slider
                        track-color="light"
                        color="warning"
                        max="2000"
                        min="0"
                        step="10"
                        v-model="financeFiltersStudent.subscribed"
                    >
                      <template v-slot:thumb-label="props" >
                        {{ props.value }}
                      </template>
                    </v-range-slider>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item v-if="$store.getters.isInRole(102)">
                <v-row>
                  <v-col cols="4">
                    <span>عدد المحاسبين</span>
                  </v-col>
                  <v-col cols="8">
                    <v-range-slider
                        track-color="light"
                        color="success"
                        max="2000"
                        min="0"
                        step="10"
                        v-model="financeFiltersStudent.active"
                    >
                      <template v-slot:thumb-label="props" >
                        {{ props.value }}
                      </template>
                    </v-range-slider></v-col>
                </v-row>
              </v-list-item>
            </v-list>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  text
                  @click="filtersMenuStudent = false; resetStudentFilters()"
              >
                الغاء التصفية
              </v-btn>
              <v-btn
                  color="primary"
                  text
                  @click="filtersMenuStudent = false; save()"
              >
                بحث
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="auto">
        <v-menu
            v-model="filtersMenuTeacher"
            :close-on-content-click="false"
            :nudge-width="250"
            offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                :color="financeFiltersTeacherActive ? 'warning' : 'light'"
                v-bind="attrs"
                v-on="on"
            >
              تصفية حسب عدد الكادر التدريسي
            </v-btn>
          </template>

          <v-card >
            <v-card-title class="subtitle-1 primary" style="color: white;"> تصفية حسب عدد الكادر التدريسي </v-card-title>
            <v-list>
              <v-list-item>
                <v-row>
                  <v-col cols="4">
                    <span>عدد الحالي</span>
                  </v-col>
                  <v-col cols="8">
                    <v-range-slider
                        track-color="light"
                        color="primary"
                        max="2000"
                        min="0"
                        step="10"
                        v-model="financeFiltersTeacher.current"
                    >
                      <template v-slot:thumb-label="props" >
                        {{ props.value }}
                      </template>
                    </v-range-slider></v-col>
                </v-row>
              </v-list-item>
            </v-list>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  text
                  @click="filtersMenuTeacher = false; resetTeacherFilters()"
              >
                الغاء التصفية
              </v-btn>
              <v-btn
                  color="primary"
                  text
                  @click="filtersMenuTeacher = false; save()"
              >
                بحث
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="auto">
        <v-menu
            v-model="filtersMenuDriver"
            :nudge-width="250"
            offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                :color="financeFiltersDriverActive ? 'warning' : 'light'"
                v-bind="attrs"
                v-on="on"
            >
              تصفية حسب عدد السواق
            </v-btn>
          </template>

          <v-card >
            <v-card-title class="subtitle-1 primary" style="color: white;"> تصفية حسب عدد السواق</v-card-title>
            <v-list>
              <v-list-item>
                <v-row>
                  <v-col cols="4">
                    <span>عدد الحالي</span>
                  </v-col>
                  <v-col cols="8">
                    <v-range-slider
                        track-color="light"
                        color="primary"
                        max="2000"
                        min="0"
                        step="10"
                        v-model="financeFiltersDriver.current"
                    >
                      <template v-slot:thumb-label="props" >
                        {{ props.value }}
                      </template>
                    </v-range-slider></v-col>
                </v-row>
              </v-list-item>
            </v-list>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  text
                  @click="filtersMenuDriver = false; resetDriverFilters()"
              >
                الغاء التصفية
              </v-btn>
              <v-btn
                  color="primary"
                  text
                  @click="filtersMenuDriver = false; save()"
              >
                بحث
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "schoolFilters",
  props: ["filterObj", "directorates", "schoolYears", "governorates", "selectedSchoolYear", "financeFiltersStudent","agencies","financeFiltersTeacher", "financeFiltersDriver"],
  emits: ['refreshTable'],
  data() {
    return {
      createdAtMenu: false,
      filtersMenuStudent: false,
      filtersMenuTeacher: false,
      filtersMenuDriver: false,
      owner: [
        {
          text: this.$t("all"),
          value: null,
        },
        {
          text: this.$t("ownershipTypes.private"),
          value: "private_schools",
        },
        {
          text: this.$t("ownershipTypes.public"),
          value: "public_schools",
        },
      ],
      activeStatus: [
        {
          text: this.$t("all"),
          value: null,
        },
        {
          text: this.$t("activationStatus.active"),
          value: 1,
        },
        {
          text: this.$t("activationStatus.inactive"),
          value: 2,
        },
        {
          text: this.$t("activationStatus.disabled"),
          value: 0,
        },
      ],
      ownerList: {
        private_schools: this.$t("ownershipTypes.private"),
        public_schools: this.$t("ownershipTypes.public"),
      },
      isFreeList: [
        {
          text: "",
          value: 1,
        },
        {
          text: this.$t("$"),
          value: 0,
        },
      ],
      freeList: [
        {
          text: this.$t("all"),
          value: null,
        },
        {
          text: this.$t("free"),
          value: 1,
        },
        {
          text: this.$t("paid"),
          value: 0,
        },
      ],
      financial_status_student: [
        {
          text: this.$t("financial_accounted.financial_accounted_schools"),
          value: 1,
        }, {
          text: this.$t("financial_accounted.not_financial_accounted_schools"),
          value: 2,
        }, {
          text: this.$t("financial_accounted.no_subscribers"),
          value: 3,
        }, {
          text: this.$t("financial_accounted.subscribers_more_than_0"),
          value: 4,
        }, {
          text: this.$t("financial_accounted.paid_more_than_0"),
          value: 5,
        }, {
          text: this.$t("financial_accounted.no_paid"),
          value: 6,
        }, {
          text: this.$t("financial_accounted.actual_more_than_expected"),
          value: 7,
        },
      ],
      studentCounts: [
        {
          text: this.$t("financial_accounted.financial_accounted_schools"),
          value: 1,
        }, {
          text: this.$t("financial_accounted.not_financial_accounted_schools"),
          value: 2,
        }, {
          text: this.$t("financial_accounted.no_subscribers"),
          value: 3,
        }, {
          text: this.$t("financial_accounted.subscribers_more_than_0"),
          value: 4,
        }, {
          text: this.$t("financial_accounted.paid_more_than_0"),
          value: 5,
        }, {
          text: this.$t("financial_accounted.no_paid"),
          value: 6,
        }, {
          text: this.$t("financial_accounted.actual_more_than_expected"),
          value: 7,
        },
      ],
    };
  },
  computed: {
    financeFiltersStudentActive() {
      const filled = Object.keys(this.financeFiltersStudent).filter(key => {
        if (this.financeFiltersStudent[key][0] !== 0 || this.financeFiltersStudent[key][1] !== 0)
          return true;
      });
      return filled.length > 0;
    },
    directorates_filtered() {
      return this.directorates
          .filter(directorate => directorate.governorate_id === this.filterObj.governorate_id || !this.filterObj.governorate_id);
    },
    financeFiltersTeacherActive() {
      const filled = Object.keys(this.financeFiltersTeacher).filter(key => {
        if (this.financeFiltersTeacher[key][0] !== 0 || this.financeFiltersTeacher[key][1] !== 0)
          return true;
      });
      return filled.length > 0;
    },
    financeFiltersDriverActive() {
      const filled = Object.keys(this.financeFiltersDriver).filter(key => {
        if (this.financeFiltersDriver[key][0] !== 0 || this.financeFiltersDriver[key][1] !== 0)
          return true;
      });
      return filled.length > 0;
    },
    localSelectedSchoolYear: {
      get: function() {
        return this.selectedSchoolYear
      },
      set: function(value) {
        this.$emit('update:selectedSchoolYear', value)
      }
    }
  },
  methods: {
    save() {
      this.$emit('update:financeFiltersStudent', this.financeFiltersStudent)
      this.$emit('update:financeFiltersTeacher', this.financeFiltersTeacher)
      this.$emit('update:financeFiltersDriver', this.financeFiltersDriver)
      this.refreshTable()
    },
    refreshTable() {
      this.$emit('refreshTable')
    },
    resetStudentFilters() {
      Object.keys(this.financeFiltersStudent).filter(key => {
        this.financeFiltersStudent[key] = [0, 0]
      });
      this.refreshTable();
    },
    resetTeacherFilters() {
      Object.keys(this.financeFiltersTeacher).filter(key => {
        this.financeFiltersTeacher[key] = [0, 0]
      });
      this.refreshTable();
    },
    resetDriverFilters() {
      Object.keys(this.financeFiltersDriver).filter(key => {
        this.financeFiltersDriver[key] = [0, 0]
      });
      this.refreshTable();
    },
    updateSelectedSchoolYear(value) {
      this.$emit('update:selectedSchoolYear', value)
    }
  }
}
</script>

<style scoped>

</style>
