<template>
  <v-dialog @click:outside="close" v-model="dialog" max-width="800px">
    <div>
      <v-btn class="mb-4 sticky" color="green darken-1" block dark @click="print">
        <v-icon> mdi-printer-outline </v-icon>
        {{ $t("print") }}
      </v-btn>
      <v-card class="px-4" style="text-align: right" id="print">
        <!--Schools Tables-->


        <v-row v-for="school in schoolsList" :key="school.id" >
          <v-col cols="12" >
            <v-img src="../../assets/aa.png"></v-img>
          </v-col>
          <v-col cols="12" style="z-index: 1111111111111111111111111111;margin-top: -6.50875cm;height: 4.048125cm;">
            <table
                style="
                width: 75%;
                text-align: right;
                margin-bottom: 5px;
                margin-right: auto;
                margin-left: auto;
              "
            >
              <tr>
                <td style="text-align: center">{{ school.name }}</td>
                <td
                    style="
                    width: 125px;
                    background-color: lightgray;
                    text-align: center;

                  "
                >
                  {{ $t("schools.schoolName") }}
                </td>
              </tr>
              <tr>
                <td style="text-align: center">
                  {{ school.domain }}.school.iq
                </td>
                <td
                    style="
                    width: 125px;
                    background-color: lightgray;
                    text-align: center;
                  "
                >
                  {{ $t("schools.domain") }}
                </td>
              </tr>
              <tr>
                <td style="text-align: center">
                  {{ school.email }}
                </td>
                <td
                    style="
                    width: 125px;
                    background-color: lightgray;
                    text-align: center;
                  "
                >
                  {{ $t("accounts.email") }}
                </td>
              </tr>
              <tr>
                <td style="text-align: center">{{ school.password }}</td>
                <td
                    style="
                    width: 125px;
                    background-color: lightgray;
                    text-align: center;
                  "
                >
                  {{ $t("accounts.password") }}
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>

        <!--Schools Names-->
        <v-row v-if="!schoolsList || schoolsList.length === 0">
          <v-col cols="12">
            <v-alert color="warning">لا يوجد بيانات للطباعة</v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div
                v-for="school in schoolsList"
                :key="school.id + 100"
                style="padding: 10px"
            >
              <p
                  style="
                  direction: rtl;
                  text-align: center;
                  font-size: 24px;
                  margin-bottom: 20px;
                "
              >
                {{ school.name | truncate(50) }} /
                {{
                  school.directorate.governorate != undefined
                      ? school.directorate.governorate.name
                      : null
                }}
              </p>
              <hr />
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
export default {
  props: ["onClose", "dialog", "schools"],
  data() {
    return {
      schoolsList: null,
      dialogSave: false,
      dialogDelete: false,
      accountToDelete: null,
      accountToSave: null,
      loading: false,
      valid: true,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.schools.forEach(function (element) {
          var superAccount = element.accounts.filter(
              (account) => account.type == 1
          );

          if (superAccount.length > 0) {
            element.email = superAccount[0].email;
            element.password = superAccount[0].password;
          }
        });
        this.schoolsList = this.schools.filter(
            (school) =>
                school.email != undefined &&
                school.email != "" &&
                school.email != null
        );
      }
    },
  },
  methods: {
    close() {
      this.account = null;
      this.$emit("closePrint");
    },
    print() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("print").innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
          "",
          "",
          "left=20,top=20,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
                              <html>
                                <head>
                                  ${stylesHtml}
                                  <style>
                                  .row {
                                          margin: 0 !important;
                                          padding: 0 !important;
                                  }
                                  </style>
                                </head>
                                <body>
                                  ${prtHtml}
                                </body>
                              </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.onload = function() {
        this.print();
        this.close();
      };
    },
  },
};
</script>
<style scoped>

td, table {
  border: 1px solid black;
}
td[data-v-696f7e01], table[data-v-696f7e01]{
  border: 1px solid #bcbcbc!important;
}
table[data-v-696f7e01]{
  width: 65%!important;
  border-radius:10% !important ;
}
td[data-v-696f7e01]{
  line-height: 20px;
}
table {
  direction: ltr;
  border-collapse: collapse;
}

@media print {
  div {
    break-inside: avoid;
  }
}

td {
  padding: 5px;
}
.sticky {
  position: sticky;
  top: 0;
  z-index: 999;
}
</style>
